import { Avatar } from "@nextui-org/react";
import { apiUrl } from "../utils/Client";
import getColor from "../utils/GetColor";
import { User } from "feathers-backend";
import { memo } from "react";

type Props = {
  user: User;
  size?: "tiny" | "sm" | "md" | "lg";
};

// Moved outside the component to avoid re-creation on each render
const getInitials = (name: string = "") => {
  const parts = name.split(" ");
  const initials = parts.map((part) => part[0]).join("");
  return initials.length > 1 ? initials : "?";
};

const UserAvatar = memo(({ user, size = "sm" }: Props) => {
  const avatarSizeStyle = size === "tiny" ? "w-6 h-6" : "";
  const avatarSizeProp = size === "tiny" ? "sm" : size;
  const fontSize = size === "tiny" ? "10px" : "12px";

  return (
    <Avatar
      style={{ backgroundColor: user ? getColor(user.fullName ?? "") : "#eee", color: "black", fontWeight: 500 }}
      showFallback
      fallback={
        user ? (
          <span style={{ fontSize: fontSize }} className="uppercase">
            {getInitials(user.fullName)}
          </span>
        ) : (
          "?"
        )
      }
      radius="sm"
      name={user?.fullName}
      src={apiUrl + user.avatar}
      className={avatarSizeStyle}
      size={avatarSizeProp}
    />
  );
});

export default UserAvatar;
