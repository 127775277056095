import { Tabs, Tab, Button, ButtonGroup } from "@nextui-org/react";
import { Board, Contact, Customer, Opportunity } from "feathers-backend";
import { useContext, useEffect, useState } from "react";
import ViewOpportunity from "./ViewOpportunity";
import OpportunityDetails from "./OpportunityDetails";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { Form, Formik } from "formik";
import { ModalContext } from "../../../contexts/ModalContext";
import CustomerForm from "../Customer/CustomerForm";
import ContactForm from "../Contact/ContactForm";
import SelectCustomerForOpportunity from "../Fields/SelectCustomerForOpportunity";
import { client } from "../../../utils/Client";
import OpportunityProcess from "./OpportunityProcess";
import OpportunityActivity from "./OpportunityActivity";
import SelectCustomer from "../Fields/SelectCustomer";
import SelectAssigneeDirect from "../Fields/SelectAssigneeDirect";
import SelectContactForOpportunity from "../Fields/SelectContactForOpportunity";

type Props = {
  opportunity: Opportunity;
  board?: Board;
};

export default function OpportunityForm({ opportunity, board }: Props) {
  const { opportunities } = useContext(DataContext);
  const [currentOpportunity, setCurrentOpportunity] = useState<Opportunity>(opportunity);
  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    const updatedOpportunity = opportunities.find((op) => op._id === opportunity._id);
    if (updatedOpportunity) {
      setCurrentOpportunity(updatedOpportunity);
    }
  }, [opportunities, opportunity._id]);

  const { lang } = useContext(UserContext);

  const handleSave = (values: Opportunity) => {
    // Implement your save logic here

    // If the opportunity has a customerId we dont need to update opportunity.customer
    if (values.customerId) {
      delete values.customer;
    }

    // If the opportunity has a contactId we dont need to update opportunity.contact
    if (values.contactId) {
      delete values.contact;
    }

    client.service("opportunities").patch(opportunity._id.toString(), { ...values });
  };

  return (
    <div>
      <div className="flex flex-col rounded-lg">
        <div className="flex flex-col w-full">
          <Formik initialValues={currentOpportunity} onSubmit={handleSave} enableReinitialize>
            {({ values, handleChange, handleBlur, dirty }) => (
              <Form>
                <ViewOpportunity opportunity={values} handleChange={handleChange} handleBlur={handleBlur} />
                <div className="flex justify-between my-4">
                  <div className="w-full"></div>
                  <SelectAssigneeDirect opportunity={values} />
                </div>
                <Tabs fullWidth aria-label="Options">
                  {board && (
                    <Tab key="board" title={board.name}>
                      <OpportunityProcess
                        board={board}
                        opportunity={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </Tab>
                  )}
                  <Tab key="details" title={lang("Details")}>
                    <OpportunityDetails opportunity={values} handleChange={handleChange} handleBlur={handleBlur} />
                  </Tab>
                  <Tab key="customer" title={lang("Customer")}>
                    <h2 className="my-2 text-sm">{lang("Existing customer")}</h2>
                    <SelectCustomerForOpportunity opportunity={currentOpportunity} />
                    {!currentOpportunity.customerId && (
                      <>
                        <h2 className="my-2 text-sm">{lang("New customer")}</h2>
                        <CustomerForm
                          values={values.customer ?? ({} as Customer)}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </>
                    )}
                  </Tab>
                  <Tab key="contact" title={lang("Contact")}>
                    <h2 className="my-2 text-sm">{lang("Existing contact")}</h2>
                    <SelectContactForOpportunity
                      customer={currentOpportunity.customer}
                      opportunity={currentOpportunity}
                    />
                    {!currentOpportunity.contactId && (
                      <>
                        <h2 className="my-2 text-sm">{lang("New contact")}</h2>
                        <ContactForm
                          values={values.contact ?? ({} as Contact)}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </>
                    )}
                  </Tab>
                  <Tab key="activity" title={lang("Activity")}>
                    <OpportunityActivity opportunity={currentOpportunity}></OpportunityActivity>
                  </Tab>
                </Tabs>
                <div className="flex justify-end gap-2 mt-4">
                  <Button onPress={closeModal} variant="light">
                    {lang("Close")}
                  </Button>
                  <Button className="disabled:hidden" type="submit" color="primary" disabled={!dirty}>
                    {lang("Save")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
