import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { Selection } from "@nextui-org/react";

type IncomingCountProps = {
  tagsFilter: Selection;
};

export default function IncomingCount({ tagsFilter }: IncomingCountProps) {
  const { opportunities } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [currentCount, setCurrentCount] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);

  useEffect(() => {
    const todayTimestamp = new Date().getTime();
    const last30DaysStartTimestamp = todayTimestamp - 30 * 24 * 60 * 60 * 1000;
    const last60DaysStartTimestamp = todayTimestamp - 60 * 24 * 60 * 60 * 1000;

    const filteredOpportunities = opportunities.filter((opportunity) =>
      Array.from(tagsFilter).every((tag) => opportunity.tags?.includes(tag))
    );

    const countCurrentPeriod = filteredOpportunities.filter(
      (opportunity) =>
        (opportunity.createdAt || 0) >= last30DaysStartTimestamp && (opportunity.createdAt || 0) <= todayTimestamp
    ).length;

    const countPreviousPeriod = filteredOpportunities.filter(
      (opportunity) =>
        (opportunity.createdAt || 0) >= last60DaysStartTimestamp &&
        (opportunity.createdAt || 0) < last30DaysStartTimestamp
    ).length;

    setCurrentCount(countCurrentPeriod);
    setPreviousCount(countPreviousPeriod);
  }, [opportunities, tagsFilter]);

  const change = currentCount - previousCount;
  const percentageChangeNumber = previousCount !== 0 ? (change / previousCount) * 100 : 100;

  // Now, directly use percentageChangeNumber for display
  const percentageChangeStr = percentageChangeNumber.toFixed(2);

  return (
    <div className="flex flex-col h-full p-4 rounded-lg bg-content1 shadow-small">
      <div className="flex w-full h-10 mb-4 rounded-lg bg-card">
        <div className="my-auto ml-4 font-semibold uppercase text-foreground-500 text-tiny">
          {lang("Incoming opportunities last 30 days")}
        </div>
      </div>
      <div className="mx-auto text-4xl">{currentCount}</div>

      <div className="mx-auto text-base text-foreground-500">
        <span className="text-success">
          {percentageChangeNumber > 0 ? `+${percentageChangeStr}%` : `${percentageChangeStr}%`}
        </span>
        {" " + lang("from previous period")}
      </div>
    </div>
  );
}
