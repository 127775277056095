import { useContext } from "react";
import ClosedValue from "./ClosedValue";
import LatestWon from "./LatestWon";
import Widget from "./Widget";
import { UserContext } from "../../contexts/UserContext";
import IncomingCount from "./IncomingCount";
import NextFollowUps from "./NextFollowUps";
import { DataContext } from "../../contexts/DataContext";
import TagsFilter from "../../components/TagsFilter";
import DealsOpportunitiesChart from "./DealsOpportunitiesChart";
import ChannelChart from "./ChannelChart";

export default function Dashboard() {
  const { lang } = useContext(UserContext);
  const { tags, tagsFilter } = useContext(DataContext);

  return (
    <div className="grid gap-8 lg:grid-cols-6">
      <div className="col-span-4">
        <Widget title={lang("Opportunities & Deals")}>
          <DealsOpportunitiesChart tagsFilter={tagsFilter} />
        </Widget>
      </div>
      <div className="flex flex-col col-span-2 space-y-4">
        {tags.length > 0 && (
          <div className="flex justify-end col-span-full">
            <TagsFilter />
          </div>
        )}
        <ClosedValue tagsFilter={tagsFilter} />
        <IncomingCount tagsFilter={tagsFilter} />
      </div>
      <div className="col-span-4">
        <Widget title={lang("Channels")}>
          <ChannelChart />
        </Widget>
      </div>
      <div className="col-span-2 space-y-8">
        <NextFollowUps />
        <LatestWon />
      </div>
    </div>
  );
}
