import { useState } from "react";
import { useFormik, FormikHelpers } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../assets/Icons";

interface ResetPasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

// Initial values
const initialValues: ResetPasswordFormValues = {
  newPassword: "",
  confirmPassword: "",
};

// Form validation with Yup
const validationSchema = Yup.object({
  newPassword: Yup.string().min(6, "Password should be minimum 6 characters").required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Required"),
});

export default function ResetPassword() {
  const [resetMessage, setResetMessage] = useState("");
  const [resetError, setResetError] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");

  const resetPassword = async (newPassword: string) => {
    try {
      if (!token) throw new Error("Token is missing");

      const response = await fetch("https://api.customerconnect.se/password-reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Service-Method": "resetPassword",
        },
        body: JSON.stringify({
          token: token,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setResetMessage("Your password has been reset successfully.");
        setResetError("");
        // Optionally, redirect after a short delay
        setTimeout(() => navigate("/login"), 1500);
      } else {
        setResetError(data.message || "There was an error resetting your password.");
        setResetMessage("");
      }
    } catch (error: any) {
      setResetError(error.message || "There was an error resetting your password.");
      setResetMessage("");
      console.error("ResetPassword Error:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: ResetPasswordFormValues, helpers: FormikHelpers<ResetPasswordFormValues>) => {
      resetPassword(values.newPassword);
      helpers.setSubmitting(false);
    },
  });

  return (
    <div className="h-screen bg-cc-900">
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Logo />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={formik.handleSubmit}>
            {/* New Password Field */}
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-white">
                New Password
              </label>
              <div className="mt-2">
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cc-purple sm:text-sm sm:leading-6"
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="mt-2 text-xs text-red-400">{formik.errors.newPassword}</div>
                ) : null}
              </div>
            </div>

            {/* Confirm Password Field */}
            <div className="mt-4">
              <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-white">
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cc-purple sm:text-sm sm:leading-6"
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="mt-2 text-xs text-red-400">{formik.errors.confirmPassword}</div>
                ) : null}
              </div>
            </div>

            {/* Success Message */}
            {resetMessage && <div className="mt-4 text-xs text-green-400">{resetMessage}</div>}

            {/* Error Message */}
            {resetError && <div className="mt-4 text-xs text-red-400">{resetError}</div>}

            {/* Submit Button */}
            <div className="mt-4">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:opacity-50"
              >
                {formik.isSubmitting ? "Resetting..." : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
