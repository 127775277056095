import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react";
import { Board, Contact, Customer, Prospect } from "feathers-backend";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { BuildingOfficeIcon, ChevronDownIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import SelectSource from "../../components/Forms/Fields/SelectSource";
import SelectAssignee from "../../components/Forms/Fields/SelectAssignee";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";

type ProspectFormProps = {
  prospect?: Prospect | null;
};

export default function ProspectForm({ prospect }: ProspectFormProps) {
  const { boards } = useContext(DataContext);
  const { lang } = useContext(UserContext);

  const { closeModal } = useContext(ModalContext);
  const formik = useFormik({
    initialValues: prospect || {
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        title: "",
      } as Contact,
      customer: {
        name: "",
        website: "",
      } as Customer,
      source: "other",
      assignedTo: null,
      notes: "",
    },

    onSubmit: async (values) => {
      if (prospect) {
        if (!values.assignedTo) {
          values.assignedTo = null;
        }
        client.service("prospects").patch(prospect._id.toString(), values);
        closeModal();
      } else {
        client.service("prospects").create(values);
        closeModal();
      }
    },
  });

  const convertToOpportunity = async (boardId: string) => {
    closeModal();
    if (prospect) {
      await client
        .service("prospects")
        .convertToOpportunity({ prospectId: prospect._id.toString(), boardId: boardId }, {});
    }
  };

  const deleteProspect = async () => {
    if (prospect) {
      await client.service("prospects").remove(prospect?._id.toString());
    }
    closeModal();
  };

  const [dropdown, setDropDown] = useState<any[]>([]);

  useEffect(() => {
    // Create the static items
    const staticItems = [
      {
        label: lang("Save"),
        description: lang("Save prospect"),
        onClick: () => formik.submitForm(),
      },
      {
        label: lang("Delete"),
        description: "Delete prospect",
        onClick: deleteProspect,
      },
    ];
    const dynamicItems = boards.map((board: Board) => ({
      label: lang("Move to") + " " + board.name,
      description: lang("Move to") + " " + board.name,
      onClick: () => convertToOpportunity(board._id.toString()),
    }));
    setDropDown([...staticItems, ...dynamicItems]);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 font-semibold">Contact</div>
        <Input
          label="First name"
          name="contact.firstName"
          type="text"
          value={formik.values.contact?.firstName || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Last name"
          name="contact.lastName"
          type="text"
          value={formik.values.contact?.lastName || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Email"
          name="contact.email"
          type="email"
          value={formik.values.contact?.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <EnvelopeIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Phone"
          name="contact.phone"
          type="text"
          value={formik.values.contact?.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <DevicePhoneMobileIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Title"
          name="contact.title"
          type="text"
          value={formik.values.contact?.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="col-span-2 mt-4 font-semibold">Company</div>
        <Input
          label="Name"
          name="customer.name"
          type="text"
          value={formik.values.customer?.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <BuildingOfficeIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
          }
        />
        <Input
          label="Website"
          name="customer.website"
          type="text"
          value={formik.values.customer?.website}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          startContent={
            <div className="flex items-center pointer-events-none">
              <span className="text-default-400 text-small">https://</span>
            </div>
          }
        />
        <div className="col-span-2">
          <Textarea
            label="Notes"
            name="notes"
            type="text"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <SelectSource
          selectedKeys={[formik.values.source]}
          name="source"
          onChange={formik.handleChange}
          value={formik.values.source}
        />
        <SelectAssignee
          name="assignedTo"
          onChange={(name, value) => formik.setFieldValue(name, value)}
          value={formik.values.assignedTo?.toString() || undefined}
        />
      </div>
      <div className="flex justify-end gap-2 mt-6 mb-3">
        <Button onPress={closeModal} variant="light">
          {lang("Close")}
        </Button>
        <ButtonGroup variant="solid" color="primary">
          <Button type="submit" color="primary">
            {prospect ? lang("Save") : lang("Create")}
          </Button>
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Button className="border-l border-ash " isIconOnly>
                <ChevronDownIcon className="w-4 h-4" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Prospect options" className="max-w-[300px]" items={dropdown}>
              {(item: any) => (
                <DropdownItem key={item.label} onClick={item.onClick}>
                  {item.label}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </ButtonGroup>
      </div>
    </form>
  );
}
