import { Outlet, useOutletContext, useParams } from "react-router-dom";
import CustomerNav from "./CustomerNav";
import { EnvelopeIcon, HeartIcon } from "@heroicons/react/24/outline";
import { Customer } from "feathers-backend";
import { useContext, useEffect, useState } from "react";
import { client } from "../../utils/Client";
import { DataContext } from "../../contexts/DataContext";
import Avatar from "../../components/Avatar";

type ContextType = { customer: Customer | null };
export function useCustomer() {
  return useOutletContext<ContextType>();
}

export default function CustomerShow() {
  const params = useParams();
  const [customer, setCustomer] = useState<Customer>();

  useEffect(() => {
    const fetchCustomer = async (id: string) => {
      const response = await client.service("customers").get(id);
      setCustomer(response);
    };
    if (params.id) {
      fetchCustomer(params.id);
    }
  }, []);

  return (
    <>
      {customer && (
        <div className="px-4 mx-auto lg:px-8">
          <div className="flex pb-4 mb-8">
            <div className="my-auto ml-2 mr-4">
              <Avatar name={customer.name ?? ""} radius="md" size="lg" image={customer.avatar} />
            </div>
            <div className="flex flex-col">
              <h1 className="text-2xl text-left text-offwhite">{customer.name}</h1>
              <div className="text-left text-placehold">{customer.website}</div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
            <div className="col-span-1 space-y-8">
              <div className="p-8 rounded-lg bg-content1">
                <CustomerNav customerId={customer._id.toString()} />
              </div>
            </div>
            <div className="col-span-3 space-y-8">
              <div className="">
                <Outlet context={{ customer }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
