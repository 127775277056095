import { useEffect, useState } from "react";
import { useCustomer } from "./CustomerShow";
import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { client } from "../../utils/Client";

export default function CustomerRexorProjects() {
  const { customer } = useCustomer();
  const [projects, setProjects] = useState<any[]>([]);

  const translateStatus = (status: number) => {
    switch (status) {
      case 0:
        return "Inaktiv";
      case 10:
        return "För godkännande";
      case 20:
        return "Pågående";
      case 30:
        return "Pausat";
      case 40:
        return "För avslut";
      case 50:
        return "Avslutat";
      case 60:
        return "Arkiverat";
      default:
        return "Unknown";
    }
  };

  // Function to open the project in Rexor in a new tab
  const openProject = (url: string) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    // Fetch projects for the customer
    const fetchProjects = async () => {
      const projects = await client.service("rexor").getProject({
        UID: customer?.customCustomerId || "",
      });
      setProjects(projects);
      console.log(projects);
    };
    fetchProjects();
  }, [customer]); // Add customer as a dependency to fetch projects when the customer changes

  return (
    <Table aria-label="Projects table">
      <TableHeader>
        <TableColumn>Beskrivning</TableColumn>
        <TableColumn>Projektägare</TableColumn>
        <TableColumn>Status</TableColumn>
        <TableColumn> </TableColumn>
      </TableHeader>
      <TableBody emptyContent={"Inga projekt hittade"}>
        {projects.map((project) => (
          <TableRow key={project.ID}>
            <TableCell>{project.Description}</TableCell>
            <TableCell>{project.ProjectOwnerResourceName}</TableCell>
            <TableCell>{translateStatus(project.Status)}</TableCell>
            <TableCell>
              <Button
                onClick={() => openProject(`https://cloud.rexor.se/briab/project?${project.UID}`)}
                size="sm"
                color="primary"
              >
                Gå till projekt
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
