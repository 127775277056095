import React, { createContext, useState } from "react";
import { Modal as ModalComponent, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";

interface ModalContextProps {
  openModal: (title: string, content: string | React.ReactNode) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export function ModalProvider(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string | React.ReactNode>();
  const [modalTitle, setModalTitle] = useState<string | React.ReactNode>();
  const openModal = (title: string | React.ReactNode, content: string | React.ReactNode) => {
    setModalTitle(title);
    setModalContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {props.children}
      <ModalComponent
        classNames={{
          base: "bg-modal border-ash border",
        }}
        isDismissable={false}
        backdrop="blur"
        size="2xl"
        scrollBehavior="outside"
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 font-normal ">
            <div className="flex">{modalTitle}</div>
          </ModalHeader>
          <ModalBody>{modalContent}</ModalBody>
        </ModalContent>
      </ModalComponent>
    </ModalContext.Provider>
  );
}
