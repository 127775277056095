// src/pages/ForgotPassword.tsx
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../assets/Icons";

// Set the API URL for password reset
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";

interface ForgotPasswordFormValues {
  email: string;
}

// Initial values
const initialValues: ForgotPasswordFormValues = {
  email: "",
};

// Form validation with Yup
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
});

export default function ForgotPassword() {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const sendResetLink = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/password-reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Service-Method": "forgotPassword",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("If the email exists, a password reset link has been sent.");
        setError("");
      } else {
        const result = await response.json();
        setError(result.message || "There was an error sending the password reset link.");
        setMessage("");
      }
    } catch (error: any) {
      setError("There was an error sending the password reset link.");
      setMessage("");
      console.error("ForgotPassword Error:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      sendResetLink(values.email);
    },
  });

  return (
    <div className="h-screen bg-cc-900">
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Logo />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cc-purple sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="mt-2 text-xs text-red-400">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            {message && <div className="mt-4 text-xs text-green-400">{message}</div>}
            {error && <div className="mt-4 text-xs text-red-400">{error}</div>}

            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Send Reset Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
