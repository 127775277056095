import { DataContext } from "../../contexts/DataContext";
import { useContext, useEffect, useState } from "react";
import { useCustomer } from "./CustomerShow";
import OpportunityForm from "../../components/Forms/Opportunity/OpportunityForm";
import { ModalContext } from "../../contexts/ModalContext";
import { UserContext } from "../../contexts/UserContext";
import { Customer, Opportunity } from "feathers-backend";
import GenericTable from "../../components/Table/GenericTable";
import NewOpportunity from "../../components/Forms/Opportunity/NewOpportunity";

export default function CustomerDeals() {
  const { opportunities, users } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  const { customer } = useCustomer();
  const { lang } = useContext(UserContext);
  const [customerOpportunities, setCustomerOpportunities] = useState<Opportunity[]>([]);

  // Filter the opportunities that has this customerId in opportunity.customerId
  useEffect(() => {
    const opportunityList = opportunities.filter((opportunity) => {
      return opportunity.customerId === customer?._id;
    });
    setCustomerOpportunities(opportunityList);
  }, [opportunities, customer]);

  return (
    <div>
      {customerOpportunities && users && (
        <GenericTable
          data={customerOpportunities}
          onRowClick={(item) => {
            openModal("", <OpportunityForm opportunity={item as Opportunity} />);
          }}
          addFunction={() => {
            openModal(lang("Add opportunity"), <NewOpportunity customer={customer as Customer} />);
          }}
          defaultSort={{ column: "closedDate", direction: "descending" }}
          filterOnTags={true}
          columns={[
            {
              name: lang("Deal"),
              uid: "deal",
              sortable: false,
            },
            {
              name: lang("Value"),
              uid: "value",
              sortable: true,
            },
            {
              name: lang("Closed Date"),
              uid: "closedDate",
              sortable: true,
            },
            {
              name: lang("Tags"),
              uid: "tags",
              sortable: false,
            },
            {
              name: lang("Closed By"),
              uid: "closedBy",
              sortable: true,
            },
          ]}
          initialVisibleColumns={["deal", "value", "closedDate", "tags", "closedBy"]}
        />
      )}
    </div>
  );
}
