import { NavLink } from "react-router-dom";
import Logo from "../assets/Icons";
import NavItem from "./NavItem";
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";

type DesktopNavProps = {
  navigation: any[];
};

const customNavigation = [{ name: "Avtal", to: "https://app.oneflow.com/c/472856/contracts/all" }];

export default function DesktopNav({ navigation }: DesktopNavProps) {
  const { me } = useContext(AuthContext);

  return (
    <div className="hidden bg-content1 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex flex-col px-6 pb-4 overflow-y-auto grow gap-y-5">
        <div className="flex items-center h-16 mt-2 shrink-0">
          <Logo />
        </div>
        <div className="divide-y divide-ash">
          <nav className="flex flex-col flex-1 mt-2">
            <ul className="flex flex-col flex-1 gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item, i) => (
                    <NavItem key={i} item={item} />
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          {me.currentTeam?.links && me.currentTeam?.links.length > 0 && (
            <nav className="flex flex-col flex-1 mt-2">
              <ul className="flex flex-col flex-1 gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {me.currentTeam.links.map((item, i) => (
                      <NavLink
                        target="_blank"
                        key={i}
                        to={item.url}
                        className="flex p-2 text-sm font-semibold leading-6 text-gray-400 rounded-md group gap-x-3 hover:text-white hover:bg-cc-700"
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
