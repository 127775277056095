import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { Chip } from "@nextui-org/react";
import { BellAlertIcon } from "@heroicons/react/24/outline";

type NavItemProps = {
  item: {
    name: string;
    to: string;
    icon?: any;
    number?: number;
  };
};

export default function NavItem({ item }: NavItemProps) {
  const { lang } = useContext(UserContext);
  const [showChip, setShowChip] = useState(false);

  useEffect(() => {
    if (item.number && item.number > 0) setShowChip(true);
  }, [item]);

  return (
    <li key={item.name}>
      <NavLink
        to={item.to}
        className={({ isActive }) =>
          isActive
            ? "bg-card text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:text-white hover:bg-cc-700 transition"
            : "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white transition hover:bg-cc-700"
        }
      >
        <item.icon className="w-6 h-6 shrink-0" aria-hidden="true" />
        <div className="flex justify-between w-full">
          <div>{lang(item.name)}</div>
        </div>
      </NavLink>
    </li>
  );
}
