import { createClient } from "feathers-backend";
import io from "socket.io-client";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";

// if env API_URL is set, use it, otherwise use localhost
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";

const connection = socketio(io(apiUrl));
export const client = createClient(connection).configure(authentication({ storage: window.localStorage }));
