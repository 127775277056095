import { User } from "feathers-backend";
import { Formik, useFormik } from "formik";
import { apiUrl, client } from "../../utils/Client";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, Checkbox, Input } from "@nextui-org/react";
import { UserContext } from "../../contexts/UserContext";
import { useDropzone } from "react-dropzone";
import { DataContext } from "../../contexts/DataContext";
import { error } from "console";
import { object, string, ref } from "yup";

export default function Profile() {
  const { me, updateAvatar } = useContext(AuthContext);
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [user, setUser] = useState<User>();
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success message state

  useEffect(() => {
    if (me._id) {
      setUser(me);
      // find my settings in teams.users by userId
      const settings = me.currentTeam?.users.find((teamUser: any) => teamUser.userId === me._id);
      if (settings) {
        setEmailNotifications(settings.notifications?.email || false);
      }
    }
  }, [me, users]);

  // Automatically clear the success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000); // Disappears after 3 seconds

      return () => clearTimeout(timer); // Clear the timer if the component unmounts or successMessage changes
    }
  }, [successMessage]);

  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const passwordSchema = object({
    password: string()
      .required("Please enter a password")
      // check minimum characters
      .min(8, "Password must have at least 8 characters"),
    // different error messages for different requirements
    confirmPassword: string()
      .required("Please re-type your password")
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([ref("password")], "Passwords does not match"),
  });

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      // You can handle multiple files, but for avatar, we'll use the first one
      const file = acceptedFiles[0];

      // Use FormData to prepare the data for the HTTP request
      const formData = new FormData();
      formData.append("avatar", file);

      try {
        // Send an HTTP POST request to your service with the FormData
        const token = localStorage.getItem("feathers-jwt"); // assuming you're storing the token in local storage

        const response = await fetch(`${apiUrl}/users/${me._id}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        // Parse the response JSON
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Error uploading avatar.");
        } else {
          updateAvatar(data.avatar);
        }
      } catch (error) {
        console.error("Error uploading avatar:", error);
      }
    },
    [me._id]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png", ".webp"],
    },
    multiple: false,
  });

  const formik = useFormik({
    initialValues: user || ({} as User),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const teamId = me.currentTeamId; // Assuming you have access to the current team ID
      const teamUsers = me.currentTeam?.users;
      const teamUser = teamUsers?.find((teamUser: any) => teamUser.userId === me._id);
      if (teamId && teamUsers) {
        // Update the user's email notification settings
        if (teamUser) {
          teamUser.notifications = teamUser.notifications ?? {};
          teamUser.notifications.email = emailNotifications;
        }

        const result = await client.service("teams").patch(teamId.toString(), {
          users: teamUsers,
        });
      }
      if (user) {
        // @ts-ignore
        if (values.notifications) {
          // @ts-ignore
          delete values.notifications;
        }
        await client.service("users").patch(user._id.toString(), values);
        setSuccessMessage(lang("Profile updated successfully")); // Set success message
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 mb-4">
            <div className="p-1 border-2 border-dashed rounded-xl border-ash w-44 h-44" {...getRootProps()}>
              <input {...getInputProps()} />
              <Avatar
                showFallback
                fallback={me.fullName}
                name={me.fullName}
                radius="lg"
                className="w-full h-full"
                src={apiUrl + me.avatar}
              />
            </div>
          </div>
          <Input
            label="First name"
            name="firstName"
            type="text"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Last name"
            name="lastName"
            type="text"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="Email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            startContent={
              <EnvelopeIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
            }
          />
          <Input
            label="Phone"
            name="phone"
            type="text"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            startContent={
              <DevicePhoneMobileIcon className="flex-shrink-0 w-4 h-4 text-2xl pointer-events-none text-default-400" />
            }
          />
          <Input
            label="Title"
            name="title"
            type="text"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="LinkedIn"
            name="linkedIn"
            type="text"
            value={formik.values.linkedIn}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <p>{lang("Notification settings")}</p>
          <Checkbox isSelected={emailNotifications} onValueChange={setEmailNotifications}>
            {lang("Email")}
          </Checkbox>
        </div>
        {successMessage && <div className="my-2 text-success">{successMessage}</div>} {/* Success message */}
        <div className="flex justify-end gap-2 mt-6 mb-3">
          <Button type="submit" color="primary">
            {lang("Save")}
          </Button>
        </div>
      </form>

      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={passwordSchema}
        onSubmit={(values, actions) => {
          user && client.service("users").patch(user._id.toString(), { password: values.password });
          actions.resetForm();
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="my-4">Change password</div>
            <div className="flex justify-between space-x-4">
              <Input
                type="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
                name="password"
                placeholder="Password"
              />
              <Input
                type="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.confirmPassword}
                name="confirmPassword"
                placeholder="Confirm Password"
              />
            </div>
            {props.errors && (
              <div className="my-2 text-small text-placehold" id="feedback">
                <div>{props.errors.password}</div>
                <div>{props.errors.confirmPassword}</div>
              </div>
            )}
            <div className="flex justify-end gap-2 mt-6 mb-3">
              <Button type="submit" color="primary">
                Change password
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
