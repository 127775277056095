import { Board, Customer, Opportunity } from "feathers-backend";
import { useContext } from "react";
import { Button, Input, Textarea } from "@nextui-org/react";
import { BuildingOfficeIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { client } from "../../../utils/Client";

type OpportunityDetailsProps = {
  opportunity: Opportunity;
  board?: Board;
  customer?: Customer;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
};

export default function OpportunityDetails({ opportunity, handleBlur, handleChange }: OpportunityDetailsProps) {
  const { me } = useContext(AuthContext);
  const { lang } = useContext(UserContext);
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2 mt-4 text-small">{lang("Details")}</div>
      <Input
        className="col-span-2"
        label={lang("Display name")}
        name="name"
        type="text"
        value={opportunity.name || ""}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {opportunity.message && opportunity.message !== "" && (
        <div className="col-span-2">
          <Textarea
            disabled
            label={lang("Message")}
            name="message"
            type="text"
            value={opportunity.message || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      )}
      <div className="col-span-2">
        <Textarea
          label={lang("Notes")}
          name="notes"
          type="text"
          value={opportunity.notes || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {opportunity.status === "closedWon" && (
        <Input
          label={lang("Value")}
          name="value"
          type="number"
          value={opportunity.value?.toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}

      {opportunity.status !== "closedWon" && (
        <Input
          label={lang("Expected value")}
          name="expectedValue"
          type="number"
          value={opportunity.expectedValue?.toString() || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {me?.currentTeam?.integrations?.rexor && (
        <>
          <div className="col-span-2 mt-4 text-small">{lang("Rexor")}</div>
          <div className="col-span-2 space-y-2">
            <Input
              label={lang("Projektnummer")}
              name="rexorId"
              type="text"
              size="sm"
              value={opportunity.rexorId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              label={lang("Överordnat projektnummer")}
              name="rexorParentId"
              type="text"
              size="sm"
              value={opportunity.rexorParentId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </>
      )}
      {(opportunity.status === "closedLost" || opportunity.status === "closedWon") && me.role === "admin" && (
        // show delete button if opportunity is closed
        <div className="col-span-2 mt-5">
          <Button color="danger" onClick={() => client.service("opportunities").remove(opportunity._id.toString())}>
            {lang("Delete")}
          </Button>
        </div>
      )}
    </div>
  );
}
