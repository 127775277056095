import { BriefcaseIcon, ClipboardDocumentListIcon, HandThumbUpIcon, UsersIcon } from "@heroicons/react/24/outline";
import NavItem from "../../layout/NavItem";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

const navigation = (customerId: string) => [
  { name: "Details", to: `/customers/${customerId}/details`, icon: BriefcaseIcon },
  { name: "Contacts", to: `/customers/${customerId}/contacts`, icon: UsersIcon },
  { name: "Deals & Opportunities", to: `/customers/${customerId}/deals`, icon: HandThumbUpIcon },
];

type CustomerNavProps = {
  customerId: string;
};

export default function CustomerNav({ customerId }: CustomerNavProps) {
  const { me } = useContext(AuthContext);

  // Clone the navigation array to avoid direct mutation
  const navItems = [...navigation(customerId)];

  // If Rexor integration is active, add the Rexor Projects nav item
  if (me?.currentTeam?.integrations?.rexor) {
    navItems.push({
      name: "Rexor Projects",
      to: `/customers/${customerId}/rexor-projects`,
      icon: ClipboardDocumentListIcon,
    });
  }

  return (
    <nav className="space-y-1 list-none" aria-label="Sidebar">
      {navItems.map((item, i) => (
        <NavItem key={i} item={item} />
      ))}
    </nav>
  );
}
