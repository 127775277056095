export default function Formatter(value: number) {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    notation: "standard",
    currency: "SEK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}
