import { useContext, useEffect, useMemo, useState } from "react";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  FolderIcon,
  HandThumbUpIcon,
  HomeIcon,
  UserCircleIcon,
  UsersIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import TopNav from "./TopNav";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import { DataContext } from "../contexts/DataContext";

type NavItem = {
  name: string;
  to: string;
  icon: any;
  number?: number;
};

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { me } = useContext(AuthContext);
  const { boards, opportunities } = useContext(DataContext);
  const [navigation, setNavigation] = useState([] as NavItem[]);
  const [darkMode, setDarkMode] = useState(true);

  const defaultNavigation = useMemo<NavItem[]>(() => {
    return [
      { name: "Dashboard", to: "/", icon: HomeIcon },
      { name: "Prospects", to: "/prospects", icon: UserCircleIcon },
      //{ name: "Follow-up", to: "/followups", icon: CalendarDaysIcon },
      { name: "Deals", to: "/deals", icon: HandThumbUpIcon },
      { name: "Customers", to: "/customers", icon: BriefcaseIcon },
      { name: "Contacts", to: "/contacts", icon: UsersIcon },
    ];
  }, []);

  useEffect(() => {
    const newNavigation = [...defaultNavigation];
    boards.forEach((board) => {
      // calculate how many opportunities with this boardid that have no user assigned
      const opportunitiesWithNoUser = opportunities.filter(
        (opportunity) =>
          (opportunity.boardId === board._id && (opportunity.assignedTo === "" || opportunity.assignedTo === null)) ||
          opportunity.assignedTo === undefined
      ).length;

      // add board to navigation after the first item
      newNavigation.splice(2, 0, {
        name: board.name,
        to: `/board/${board._id}`,
        icon: ViewColumnsIcon,
        number: opportunitiesWithNoUser,
      });
    });
    setNavigation(newNavigation);
  }, [boards, defaultNavigation, opportunities]);

  return (
    <main className={`${darkMode ? "dark" : ""}`}>
      <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} navigation={navigation} />
      <DesktopNav navigation={navigation} />
      <div className="lg:pl-72">
        <TopNav setDarkMode={setDarkMode} darkMode={darkMode} me={me} setSidebarOpen={setSidebarOpen} />
        <div className="flex h-full text-white bg-offblack">
          <div className="w-full px-6 mx-auto max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
}
