import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { object, string, ref } from "yup";
import { client } from "../utils/Client";
import { Button, Input } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Invitation, Team } from "feathers-backend";
import Logo from "../assets/Icons";

export default function Signup() {
  const { id } = useParams();
  const [invitation, setInvitation] = useState<any | null>(null);
  const [team, setTeam] = useState<Team | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchInvitation = async (id: string) => {
    try {
      const fetchedInvitation = (await client.service("invitations").get(id)) as any;
      setInvitation(fetchedInvitation);
    } catch (err) {
      console.error("Failed to fetch invitation:", err);
      setError("Invalid or expired invitation.");
    }
  };

  const reAuthenticate = async () => {
    try {
      const res = await client.authentication.reAuthenticate();
      if (res.user.email !== invitation?.email) {
        navigate("/");
      }
    } catch (error) {
      console.error("Re-authentication error:", error);
      navigate("/login");
    }
  };

  const joinTeam = async () => {
    try {
      if (id) {
        const res = await client.service("invitations").joinTeam({
          invitationId: id,
        });
        if (!res.success) {
          throw new Error(res.message);
        }
        navigate(`/teams/${invitation?.teamId}`);
      }
    } catch (error) {
      console.error("Join team error:", error);
      setError("Failed to join the team. Please try again.");
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (id) {
        await fetchInvitation(id);
      }

      if (invitation?.existingUser) {
        try {
          const token = await client.authentication.getAccessToken();
          if (token) {
            await reAuthenticate();
          } else {
            navigate("/login");
          }
        } catch (error) {
          console.error("Re-authentication failed:", error);
          navigate("/login");
        }
      }

      setLoading(false);
    };

    initialize();
  }, [id, invitation?.existingUser]);

  const passwordSchema = object({
    firstName: string().required("Please enter your first name"),
    lastName: string().required("Please enter your last name"),
    password: string().required("Please enter a password").min(8, "Password must have at least 8 characters"),
    confirmPassword: string()
      .required("Please re-type your password")
      .oneOf([ref("password")], "Passwords do not match"),
  });

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="h-screen bg-cc-900">
      {invitation && (
        <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Logo />
            <div className="mt-4 text-center">You have been invited to {invitation.teamName || ""}</div>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">
            {invitation.existingUser ? (
              <div className="flex justify-end gap-2 mt-6 mb-3">
                <Button onPress={joinTeam} type="button" color="primary" className="mx-auto">
                  Join Team
                </Button>
              </div>
            ) : (
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  password: "",
                  confirmPassword: "",
                  email: invitation.email,
                }}
                validationSchema={passwordSchema}
                onSubmit={async (values, actions) => {
                  try {
                    if (id) {
                      await client.service("invitations").createAccount({
                        invitationId: id,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                      });
                    }
                    navigate("/login");
                  } catch (error) {
                    console.error("Account creation error:", error);
                    setError("Failed to create account. Please try again.");
                  }
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="my-4">Create Account</div>
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        name="firstName"
                        label="First Name"
                      />
                      <Input
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                        name="lastName"
                        label="Last Name"
                      />
                      <div className="col-span-2">
                        <Input
                          type="email"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          name="email"
                          label="Email"
                          isDisabled
                        />
                      </div>
                      <Input
                        type="password"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password}
                        name="password"
                        label="Password"
                      />
                      <Input
                        type="password"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.confirmPassword}
                        name="confirmPassword"
                        label="Confirm Password"
                      />
                    </div>
                    {error && (
                      <div className="my-2 text-red-500 text-small" id="feedback">
                        {error}
                      </div>
                    )}
                    <div className="flex justify-end gap-2 mt-6 mb-3">
                      <Button type="submit" color="primary">
                        Create Account
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
