import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Button,
} from "@nextui-org/react";
import { useContext, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import TagForm from "./TagForm";
import { DataContext } from "../../contexts/DataContext";
import { Tag } from "feathers-backend";

export default function Tags() {
  const { tags } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  const getTitle = () => {
    if (!open) return "Tags";
    if (open && !selectedTag) return "Add a new tag";
    if (open && selectedTag) return "Edit tag";
  };

  const columns = [
    { label: "Label", key: "text" },
    { label: "Color", key: "color" },
    { label: "ID", key: "id" },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        {getTitle()}
        <Button
          onPress={() => {
            if (open) setOpen(false);
            else {
              setSelectedTag(null);
              setOpen(true);
            }
          }}
          color={open ? "default" : "primary"}
          endContent={!open && <PlusIcon className="w-5 h-5" />}
        >
          {open ? "Go back" : "Add New"}
        </Button>
      </div>
      {open ? (
        <TagForm tag={selectedTag} setOpen={setOpen} />
      ) : (
        <Table
          classNames={{
            wrapper: "px-0 shadow-none",
            tr: "hover:bg-ash cursor-pointer",
          }}
          aria-label="Example table with dynamic content"
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody emptyContent={"No tags added"} items={tags}>
            {(item) => (
              <TableRow
                onClick={() => {
                  setSelectedTag(item);
                  setOpen(true);
                }}
                key={item._id.toString()}
              >
                <TableCell>{item.text}</TableCell>
                <TableCell>
                  <div style={{ color: item.color }}>{item.color}</div>
                </TableCell>
                <TableCell>
                  <div className="text-sm text-label">{item._id.toString()}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
