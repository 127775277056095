import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { Button, Checkbox, Input } from "@nextui-org/react";
import { AuthContext } from "../../../contexts/AuthContext";
import { client } from "../../../utils/Client";

interface BrightcallSettingsFormValues {
  apiKey: string;
  widgetId: string;
  enabled: boolean;
}

const Brightcall: React.FC = () => {
  const { me } = useContext(AuthContext);
  const brightcallSettings = me.currentTeam?.integrations?.brightcall;

  const initialValues: BrightcallSettingsFormValues = {
    apiKey: brightcallSettings?.apiKey || "",
    enabled: brightcallSettings?.enabled || false,
    widgetId: brightcallSettings?.widgetId || "",
  };

  const onSubmit = async (
    values: BrightcallSettingsFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const teamId = me.currentTeam?._id.toString();
      if (!teamId) {
        throw new Error("Team ID is not available.");
      }

      await client.service("teams").patch(teamId, {
        integrations: {
          ...me.currentTeam.integrations,
          brightcall: values,
        },
      });

      // Optionally, you can add a success message or additional logic here
    } catch (error) {
      console.error("Failed to update Brightcall settings:", error);
      // Optionally, handle errors by setting form errors or displaying notifications
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <img className="w-auto h-8 mx-auto" src="/brightcall-logo.svg" alt="Brightcall Logo" />
      <div className="mt-8 space-y-4">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize // Resets the form when initialValues change
        >
          {({ isSubmitting, handleChange, values }) => (
            <Form className="space-y-4">
              <Input
                label="API Key"
                name="apiKey"
                value={values.apiKey}
                onChange={handleChange}
                placeholder="Enter your API key"
                required
              />
              <Input
                label="Widget ID"
                name="widgetId"
                value={values.widgetId}
                onChange={handleChange}
                placeholder="Enter your widget ID"
              />

              <Checkbox
                name="enabled"
                isSelected={values.enabled}
                onChange={(e) => {
                  // Formik's handleChange doesn't automatically handle Checkbox's checked state
                  handleChange({
                    target: {
                      name: "enabled",
                      value: e.target.checked,
                    },
                  });
                }}
              >
                Enabled
              </Checkbox>
              <div>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Brightcall;
