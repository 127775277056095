import { Opportunity } from "feathers-backend";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { apiUrl, client } from "../../../utils/Client";
import moment from "moment";
import Avatar from "../../Avatar";
import { Button, Textarea } from "@nextui-org/react";
import { DataContext } from "../../../contexts/DataContext";
import { UserContext } from "../../../contexts/UserContext";

type Props = {
  opportunity: Opportunity;
};

export default function OpportunityActivity({ opportunity }: Props) {
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [activities, setActivities] = useState<any[]>([]);
  const activityContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const container = activityContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (activities && activities.length > 0) {
      scrollToBottom();
    }
  }, [activities]);

  useEffect(() => {
    const activitiesWithUser = opportunity.activities?.map((activity: any) => {
      const user = users.find((user) => user._id === activity.user);
      return { ...activity, user };
    });
    setActivities(activitiesWithUser || []);
  }, [opportunity, users]);

  const formik = useFormik({
    initialValues: { text: "" },
    onSubmit: async (values) => {
      await client.service("opportunities").addActivity({ _id: opportunity._id.toString(), text: values.text }, {});
      formik.resetForm();
    },
  });

  return (
    <div className="">
      <div ref={activityContainerRef} className="p-2 my-4 space-y-4 max-h-[27rem] overflow-y-auto">
        {activities &&
          activities.map((activity, index) => (
            <div key={index} className="flex">
              <div className="relative">
                {index !== activities.length - 1 ? (
                  <span className="absolute top-5 left-5 h-full w-0.5 bg-ash " aria-hidden="true" />
                ) : null}
                <Avatar size="md" name={activity.user?.fullName ?? ""} image={apiUrl + activity.user?.avatar} />
              </div>
              <div className="flex-1 min-w-0 ml-4">
                <div>
                  <div className="text-sm text-slate-100">{activity.user?.fullName || ""}</div>
                  <div className="text-foreground-500 text-small ">{moment(activity.createdAt).fromNow()}</div>
                </div>
                <div className="text-sm text-cc-line">
                  <div>{activity.text}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* Remove the form tag and just use the formik handleSubmit */}
      <Textarea
        label={lang("Add new comment")}
        name="text"
        type="text"
        value={formik.values.text}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div className="flex justify-end gap-2 mt-4">
        <Button
          disabled={!formik.dirty}
          className="disabled:hidden"
          size="sm"
          onClick={formik.submitForm}
          color="primary"
        >
          {lang("Comment")}
        </Button>
      </div>
    </div>
  );
}
