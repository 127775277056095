type WidgetProps = {
  title: string;
  children: React.ReactNode;
};

export default function Widget({ children, title }: WidgetProps) {
  return (
    <div className="w-full p-4 rounded-lg bg-content1 shadow-small">
      <div className="flex w-full h-10 mb-4 rounded-lg bg-card ">
        <div className="my-auto ml-4 font-semibold uppercase text-foreground-500 text-tiny">{title}</div>
      </div>
      <div className="h-72">{children}</div>
    </div>
  );
}
