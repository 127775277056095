import {
  CartesianGrid,
  Line,
  LineChart as LineChartComponent,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
  BarChart,
  Bar,
} from "recharts";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Formatter from "../../utils/Formatter";
import { UserContext } from "../../contexts/UserContext";
import { Selection } from "@nextui-org/react";

type MonthlyData = {
  name: string;
  incoming: number;
  closedValue: number; // changed the property name for clarity
};

type LineChartProps = {
  tagsFilter: Selection;
};

export default function DealsOpportunitiesChart({ tagsFilter }: LineChartProps) {
  const { opportunities } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [data, setData] = useState<MonthlyData[]>([]);

  const getMonthName = (date: any) => {
    return date.toLocaleString("default", { month: "short" });
  };

  const generateMonthlyData = () => {
    const today = new Date();
    const data = [];

    for (let i = 0; i < 12; i++) {
      const startOfMonth = new Date(today.getFullYear(), today.getMonth() - i, 1).getTime();
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() - i + 1, 0, 23, 59, 59).getTime();

      const filteredOpportunities = opportunities.filter((opportunity) =>
        Array.from(tagsFilter).every((tag) => opportunity.tags?.includes(tag))
      );

      const incomingForMonth = filteredOpportunities.filter(
        (opportunity) => (opportunity.createdAt || 0) >= startOfMonth && (opportunity.createdAt || 0) <= endOfMonth
      ).length;

      const closedValueForMonth = opportunities
        .filter(
          (opportunity) =>
            opportunity.status === "closedWon" &&
            (opportunity.closedDate || 0) >= startOfMonth &&
            (opportunity.closedDate || 0) <= endOfMonth
        )
        .reduce((totalValue, opp) => totalValue + (opp.value || 0), 0); // summing up the values

      data.unshift({
        name: getMonthName(new Date(startOfMonth)),
        incoming: incomingForMonth,
        closedValue: closedValueForMonth,
      });
    }
    return data;
  };
  useEffect(() => {
    setData(generateMonthlyData());
  }, [opportunities, tagsFilter]);

  const renderColorfulLegendText = (value: string) => {
    switch (value) {
      case lang("Incoming opportunities"):
        return <span className="text-primary">{value}</span>;
      case lang("Closed deals"):
        return <span className="text-success">{value}</span>;
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={500} height={300} data={data}>
        <Tooltip cursor={false} contentStyle={{ border: "none", backgroundColor: "#0e0e1c" }} />
        <XAxis padding={{ left: 20, right: 20 }} stroke="#D9DBE9" dataKey="name" />
        <YAxis stroke="#6E7191" yAxisId="left" />
        <YAxis
          tickFormatter={(value: number) => Formatter(value)}
          stroke="#6E7191"
          width={100}
          yAxisId="right"
          orientation="right"
        />
        <Legend formatter={renderColorfulLegendText} />
        <Bar
          yAxisId="left"
          radius={[15, 15, 0, 0]}
          barSize={10}
          type="monotone"
          name={lang("Incoming opportunities")}
          dataKey="incoming"
          className="text-primary"
          fill="#A996FF"
        />
        <Bar
          yAxisId="right"
          radius={[15, 15, 0, 0]}
          barSize={10}
          type="monotone"
          name={lang("Closed deals")}
          dataKey="closedValue"
          fill="#55D486"
        />
        <CartesianGrid horizontal={false} stroke="#262338" strokeDasharray="3 3" />
      </BarChart>
    </ResponsiveContainer>
  );
}
