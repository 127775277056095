import React from "react";

export default function Logo() {
  return (
    <svg viewBox="0 0 182 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.0868 18.0001C43.0868 15.6349 44.8551 13.8218 47.2921 13.8218C48.7602 13.8218 50.0541 14.5453 50.7577 15.681L49.1634 16.5991C48.8051 15.9794 48.1117 15.6119 47.2921 15.6119C45.8589 15.6119 44.9349 16.5646 44.9349 18.0001C44.9349 19.4356 45.8589 20.3883 47.2921 20.3883C48.1132 20.3883 48.8167 20.0208 49.1634 19.401L50.7577 20.3191C50.0643 21.4563 48.7703 22.1784 47.2921 22.1784C44.8536 22.1784 43.0868 20.3652 43.0868 18.0001Z"
        fill="white"
      />
      <path
        d="M52.7233 19.3432V13.9023H54.5713V19.1832C54.5713 19.7915 54.86 20.3089 55.8421 20.3089C56.8242 20.3089 57.1128 19.7929 57.1128 19.1832V13.9023H58.9609V19.3432C58.9609 21.0656 57.6205 22.099 55.8421 22.099C54.0636 22.099 52.7218 21.0656 52.7218 19.3432H52.7233Z"
        fill="white"
      />
      <path
        d="M61.0425 20.1923L62.6368 19.2742C62.9254 19.9401 63.423 20.3883 64.3355 20.3883C65.2479 20.3883 65.4336 20.0438 65.4336 19.7339C65.4336 19.2396 64.9708 19.045 63.7581 18.7121C62.557 18.3791 61.3776 17.8055 61.3776 16.2662C61.3776 14.7269 62.6948 13.8218 64.0932 13.8218C65.4916 13.8218 66.4621 14.4531 67.051 15.6349L65.4916 16.5415C65.2145 15.9679 64.833 15.6119 64.0932 15.6119C63.5159 15.6119 63.2272 15.8987 63.2272 16.2201C63.2272 16.5876 63.423 16.8398 64.6836 17.2189C65.9079 17.5864 67.2831 18.0116 67.2831 19.7094C67.2831 21.2588 66.0356 22.1769 64.2789 22.1769C62.5222 22.1769 61.4937 21.3727 61.044 20.1908L61.0425 20.1923Z"
        fill="white"
      />
      <path d="M74.5029 15.7504H72.4227V22.0186H70.5746V15.7504H68.4944V13.9819H74.5029V15.7504Z" fill="white" />
      <path
        d="M75.659 18.0001C75.659 15.6349 77.5419 13.8218 79.8643 13.8218C82.1868 13.8218 84.0697 15.6349 84.0697 18.0001C84.0697 20.3652 82.1868 22.1784 79.8643 22.1784C77.5419 22.1784 75.659 20.3652 75.659 18.0001ZM82.2216 18.0001C82.2216 16.5646 81.1815 15.6119 79.8643 15.6119C78.5472 15.6119 77.5071 16.5646 77.5071 18.0001C77.5071 19.4356 78.5472 20.3883 79.8643 20.3883C81.1815 20.3883 82.2216 19.4356 82.2216 18.0001Z"
        fill="white"
      />
      <path
        d="M94.3531 22.0186H92.505V17.3459L90.4133 20.756H90.2058L88.114 17.3459V22.0186H86.2659V13.9834H88.114L90.3088 17.5535L92.5036 13.9834H94.3517V22.0186H94.3531Z"
        fill="white"
      />
      <path
        d="M101.979 20.2501V22.0186H96.8947V13.9834H101.921V15.7519H98.7442V17.0836H101.632V18.829H98.7442V20.253H101.979V20.2501Z"
        fill="white"
      />
      <path
        d="M106.901 19.3781H106.023V22.0186H104.175V13.9834H107.41C108.946 13.9834 110.184 15.2114 110.184 16.7391C110.184 17.7264 109.583 18.6215 108.694 19.0697L110.416 22.02H108.429L106.904 19.3796L106.901 19.3781ZM106.023 17.7711H107.41C107.918 17.7711 108.334 17.3229 108.334 16.7377C108.334 16.1525 107.918 15.7043 107.41 15.7043H106.023V17.7711Z"
        fill="white"
      />
      <path
        d="M114.253 17.9997C114.253 15.7037 115.998 13.856 118.458 13.856C119.948 13.856 121.255 14.6141 121.924 15.7844L121.381 16.0943C120.85 15.107 119.729 14.4411 118.458 14.4411C116.331 14.4411 114.865 16.0251 114.865 17.9997C114.865 19.9742 116.331 21.5582 118.458 21.5582C119.74 21.5582 120.873 20.8808 121.404 19.8705L121.947 20.1803C121.288 21.3622 119.971 22.1434 118.458 22.1434C115.996 22.1434 114.253 20.2956 114.253 17.9997Z"
        fill="white"
      />
      <path
        d="M125.125 17.9997C125.125 15.7037 126.962 13.856 129.296 13.856C131.63 13.856 133.466 15.7037 133.466 17.9997C133.466 20.2956 131.63 22.1434 129.296 22.1434C126.962 22.1434 125.125 20.2956 125.125 17.9997ZM132.854 17.9997C132.854 16.0251 131.295 14.4411 129.296 14.4411C127.297 14.4411 125.737 16.0251 125.737 17.9997C125.737 19.9742 127.297 21.5582 129.296 21.5582C131.295 21.5582 132.854 19.9742 132.854 17.9997Z"
        fill="white"
      />
      <path
        d="M143.138 13.9814V22.0166H142.595L137.824 15.1057V22.0166H137.212V13.9814H137.743L142.525 20.8924V13.9814H143.138Z"
        fill="white"
      />
      <path
        d="M153.27 13.9814V22.0166H152.728L147.956 15.1057V22.0166H147.344V13.9814H147.875L152.658 20.8924V13.9814H153.27Z"
        fill="white"
      />
      <path
        d="M162.132 21.4445V22.0181H157.475V13.9829H162.074V14.5565H158.088V17.6784H161.785V18.252H158.088V21.443H162.132V21.4445Z"
        fill="white"
      />
      <path
        d="M165.31 17.9997C165.31 15.7037 167.055 13.856 169.516 13.856C171.005 13.856 172.312 14.6141 172.981 15.7844L172.439 16.0943C171.908 15.107 170.786 14.4411 169.516 14.4411C167.389 14.4411 165.922 16.0251 165.922 17.9997C165.922 19.9742 167.389 21.5582 169.516 21.5582C170.798 21.5582 171.931 20.8808 172.462 19.8705L173.004 20.1803C172.346 21.3622 171.029 22.1434 169.516 22.1434C167.054 22.1434 165.31 20.2956 165.31 17.9997Z"
        fill="white"
      />
      <path
        d="M181.498 14.5565H178.956V22.0181H178.332V14.5565H175.791V13.9829H181.499V14.5565H181.498Z"
        fill="white"
      />
      <path
        d="M2.90467 28.9162C2.04881 28.6712 1.34236 28.1105 0.912972 27.3395C0.226829 26.1043 0.408156 24.5693 1.36411 23.52L1.51643 23.3528L1.44825 23.1381C-1.0033 15.3825 2.41726 6.90053 9.58043 2.97014C13.6102 0.759198 18.274 0.241775 22.71 1.51299C25.0295 2.17743 27.2098 3.3319 29.0506 4.86399C29.0492 4.86399 29.0463 4.86687 29.0448 4.86687C28.3471 5.25026 27.7393 5.75615 27.2374 6.3759C25.7041 5.16522 23.9677 4.27738 22.0688 3.73402C18.229 2.63431 14.1948 3.08111 10.7061 4.99515C4.63671 8.32597 1.64118 15.5439 3.58356 22.158L3.66335 22.429L3.94767 22.4405C4.21893 22.4506 4.4902 22.4938 4.75276 22.5688C5.60718 22.8138 6.31218 23.3716 6.74011 24.1412C7.62789 25.7382 7.04184 27.7531 5.436 28.6352C4.65847 29.0618 3.76054 29.1612 2.90467 28.9162ZM4.36835 23.8732C3.86643 23.729 3.33696 23.7881 2.88001 24.0389C2.42307 24.2897 2.09088 24.7033 1.94581 25.202C1.80075 25.7007 1.86023 26.2268 2.11263 26.6808C2.36504 27.1348 2.78137 27.4648 3.28328 27.609C3.78665 27.7531 4.31468 27.694 4.77162 27.4432C5.71597 26.9258 6.05977 25.7396 5.539 24.8013C5.28659 24.3473 4.87026 24.0173 4.36835 23.8732Z"
        fill="#A996FF"
      />
      <path
        d="M13.1561 34.4869C10.8366 33.8224 8.65633 32.668 6.81549 31.1359C6.81694 31.1359 6.81984 31.133 6.82129 31.133C7.51904 30.7496 8.12685 30.2437 8.62877 29.624C10.1621 30.8347 11.8999 31.7225 13.7973 32.2659C17.6371 33.3656 21.6713 32.9188 25.16 31.0047C31.2294 27.6753 34.2235 20.4574 32.2811 13.8419L32.2013 13.5709L31.917 13.5594C31.6458 13.5493 31.3759 13.506 31.1134 13.4311C30.259 13.1861 29.5525 12.6283 29.126 11.8586C28.6966 11.0861 28.5965 10.194 28.8432 9.34359C29.0898 8.49323 29.6541 7.79132 30.4301 7.3647C31.2077 6.93808 32.1056 6.83863 32.9615 7.08365C33.8173 7.32867 34.5238 7.88933 34.9532 8.66042C35.6393 9.89416 35.458 11.4277 34.5035 12.477L34.3512 12.6441L34.4193 12.8589C36.8723 20.6145 33.4503 29.0979 26.2843 33.0297C22.2544 35.2407 17.5907 35.7581 13.1532 34.4869H13.1561ZM32.5829 8.39234C32.0795 8.24821 31.5515 8.3073 31.0945 8.55809C29.9688 9.17496 29.6961 10.7402 30.7246 11.7044C30.7913 11.7664 30.8624 11.8226 30.9393 11.8716C31.7342 12.3833 32.712 12.2968 33.3952 11.6669C33.5272 11.5444 33.6404 11.4032 33.7303 11.2461C34.0842 10.6249 34.0842 9.91146 33.755 9.31909C33.5025 8.86508 33.0862 8.53503 32.5843 8.3909L32.5829 8.39234Z"
        fill="#A996FF"
      />
      <path
        d="M22.8129 18.5953C23.3177 18.5953 23.7282 18.1888 23.7282 17.6858C23.7282 17.1828 23.3192 16.7764 22.8129 16.7764H20.1525V18.5938H22.8129V18.5953Z"
        fill="#A996FF"
      />
      <path
        d="M19.1617 16.7776V15.2427C20.6123 14.8449 21.6786 13.5246 21.6786 11.958C21.6786 10.0756 20.1423 8.54932 18.2478 8.54932C16.3533 8.54932 14.8171 10.0756 14.8171 11.958C14.8171 13.5261 15.8833 14.8449 17.3339 15.2427V16.7776H13.6827C13.1779 16.7776 12.7674 17.1841 12.7674 17.6871C12.7674 18.1901 13.1765 18.5965 13.6827 18.5965H17.3339V20.2382L13.4115 26.1662C13.1344 26.5856 13.2505 27.1492 13.6726 27.4244C13.8278 27.5253 14.0019 27.5743 14.1745 27.5743C14.4719 27.5743 14.7634 27.4302 14.939 27.165L18.2652 22.1392L21.5567 27.1131C21.8338 27.5325 22.401 27.6493 22.8231 27.3726C23.2452 27.0973 23.3627 26.5337 23.0842 26.1143L19.1617 20.1863V18.5965V16.7791V16.7776ZM18.2464 13.5491C17.363 13.5491 16.6449 12.8357 16.6449 11.958C16.6449 11.0802 17.363 10.3668 18.2464 10.3668C19.1298 10.3668 19.8479 11.0802 19.8479 11.958C19.8479 12.8357 19.1298 13.5491 18.2464 13.5491Z"
        fill="#A996FF"
      />
    </svg>
  );
}
