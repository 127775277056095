import { useState, useEffect, useContext } from "react";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { DataContext } from "../../../contexts/DataContext";
import { Opportunity, Prospect } from "feathers-backend";
import { client } from "../../../utils/Client";
import UserAvatar from "../../UserAvatar";
import { UserContext } from "../../../contexts/UserContext";

type SelectAssigneeDirectProps = {
  opportunity?: Opportunity;
  prospect?: Prospect;
};

export default function SelectAssigneeDirect({ opportunity, prospect }: SelectAssigneeDirectProps) {
  const { users } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const sortedUsers = [...(users || [])].sort((a, b) => a.fullName.localeCompare(b.fullName));

  const [selectedKey, setSelectedKey] = useState<string | null>(
    opportunity?.assignedTo?.toString() || prospect?.assignedTo?.toString() || null
  );

  useEffect(() => {
    setSelectedKey(opportunity?.assignedTo?.toString() || prospect?.assignedTo?.toString() || null);
  }, [opportunity, prospect]);

  const onSelectionChange = (key: React.Key | null) => {
    setSelectedKey(key?.toString() || null);
    if (opportunity) {
      client.service("opportunities").patch(opportunity._id.toString(), { assignedTo: key });
    } else if (prospect) {
      client.service("prospects").patch(prospect._id.toString(), { assignedTo: key ?? undefined });
    }
  };

  const onClear = () => {
    setSelectedKey(null);
    if (opportunity) {
      opportunity.assignedTo = null;
      client.service("opportunities").patch(opportunity._id.toString(), { assignedTo: undefined });
    } else if (prospect) {
      prospect.assignedTo = null;
      client.service("prospects").patch(prospect._id.toString(), { assignedTo: undefined });
    }
  };

  return (
    <>
      {users && (
        <Autocomplete
          defaultItems={sortedUsers}
          label={lang("Assigned to")}
          size="sm"
          fullWidth={true}
          placeholder={lang("Select user")}
          labelPlacement="inside"
          onClear={onClear}
          shouldCloseOnBlur={true}
          selectedKey={selectedKey}
          onSelectionChange={onSelectionChange}
        >
          {(user) => (
            <AutocompleteItem key={user._id.toString()} textValue={user.fullName}>
              <div className="flex items-center gap-2">
                <UserAvatar user={user} size="sm" />
                <div className="flex flex-col">
                  <span className="text-small">{user.fullName}</span>
                </div>
              </div>
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
    </>
  );
}
