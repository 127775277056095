import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Button,
} from "@nextui-org/react";
import { useContext, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import CustomfieldForm from "./CustomfieldForm";
import { DataContext } from "../../contexts/DataContext";
import { Customfield } from "feathers-backend";

export default function Customfields() {
  const { customfields } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [selectedCustomfield, setSelectedCustomfield] = useState<Customfield | null>(null);

  const getTitle = () => {
    if (!open) return "Custom fields";
    if (open && !selectedCustomfield) return "Add a new custom field";
    if (open && selectedCustomfield) return "Edit custom field";
  };

  const columns = [
    { label: "Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Type", key: "type" },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        {getTitle()}
        <Button
          onPress={() => {
            if (open) setOpen(false);
            else {
              setSelectedCustomfield(null);
              setOpen(true);
            }
          }}
          color={open ? "default" : "primary"}
          endContent={!open && <PlusIcon className="w-5 h-5" />}
        >
          {open ? "Go back" : "Add New"}
        </Button>
      </div>
      {open ? (
        <CustomfieldForm customfield={selectedCustomfield} setOpen={setOpen} />
      ) : (
        <Table
          classNames={{
            wrapper: "px-0 shadow-none",
            tr: "hover:bg-ash cursor-pointer",
          }}
          aria-label="Example table with dynamic content"
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody emptyContent={"No customfields added"} items={customfields}>
            {(item) => (
              <TableRow
                onClick={() => {
                  setSelectedCustomfield(item);
                  setOpen(true);
                }}
                key={item._id.toString()}
              >
                {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
