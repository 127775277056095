import { Chip, Select, SelectItem, Selection } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";

export default function TagsFilter() {
  const { me } = useContext(AuthContext);
  const { tags, setTagsFilter } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(() => {
    // Initialize state from local storage
    const storedValue = localStorage.getItem("selectedTags");
    return storedValue ? new Set(JSON.parse(storedValue)) : new Set();
  });

  useEffect(() => {
    // Initialize state from local storage
    const storedValue = localStorage.getItem("selectedTags");
    if (storedValue) {
      setSelectedKeys(new Set(JSON.parse(storedValue)));
    }
  }, []);

  useEffect(() => {
    // Save state to local storage whenever it changes
    localStorage.setItem("selectedTags", JSON.stringify(Array.from(selectedKeys)));
    // Also update the tagsFilter context
    setTagsFilter(selectedKeys);
  }, [selectedKeys]);

  return (
    <>
      {tags && tags.length !== 0 && (
        <Select
          items={tags}
          variant="bordered"
          isMultiline={true}
          selectionMode="multiple"
          selectedKeys={selectedKeys} // Set the selected keys here
          onSelectionChange={setSelectedKeys}
          placeholder={lang("Select tags")}
          size="sm"
          color="primary"
          aria-label="Select tags"
          classNames={{
            base: "bg-card rounded-xl w-full",
            trigger: "border-none",
            mainWrapper: "my-auto my-1",
          }}
          renderValue={(items) => {
            return (
              <div className="flex gap-1 mr-6">
                {items.map((item) => (
                  <Chip
                    startContent={
                      <div style={{ backgroundColor: item.data?.color }} className="w-2 h-2 rounded-full" />
                    }
                    classNames={{
                      base: "text-black text-xs ",
                    }}
                    style={{ color: item.data?.color }}
                    key={item.data?._id.toString()}
                  >
                    {item.data?.text}
                  </Chip>
                ))}
              </div>
            );
          }}
        >
          {(tag) => (
            <SelectItem aria-label={tag.text} key={tag._id.toString()} textValue={tag.text}>
              <div className="flex" style={{ color: tag.color }}>
                {tag.text}
              </div>
            </SelectItem>
          )}
        </Select>
      )}
    </>
  );
}
