import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from "@nextui-org/react";
import { useContext, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { DataContext } from "../../contexts/DataContext";
import { AuthContext } from "../../contexts/AuthContext";
import { client } from "../../utils/Client";

export default function Mailboxes() {
  const { mailboxes } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [selectedMailbox, setSelectedMailbox] = useState<any>(null);

  const getTitle = () => {
    if (!open) return "Mailboxes";
    if (open && !selectedMailbox) return "Add a New Mailbox";
    if (open && selectedMailbox) return "Edit Mailbox";
  };

  const initiateOAuth = async (provider: string) => {
    try {
      const response = await client.service("mailboxes").init({ provider }, { authenticated: true });
      if (response.redirect) {
        window.location.href = response.redirect;
      }
    } catch (error) {
      console.error("Error initiating OAuth:", error);
    }
  };

  const columns = [
    { label: "Email", key: "email" },
    { label: "Provider", key: "provider" },
    { label: "ID", key: "_id" },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">{getTitle()}</h2>
        <Button
          onPress={() => {
            if (open) setOpen(false);
            else {
              setSelectedMailbox(null);
              setOpen(true);
            }
          }}
          color={open ? "default" : "primary"}
          endContent={!open && <PlusIcon className="w-5 h-5" />}
        >
          {open ? "Go Back" : "Add New"}
        </Button>
      </div>
      {open ? (
        <div className="space-y-4">
          <Button onPress={() => initiateOAuth("gmail")} color="primary" className="w-full">
            Connect Gmail
          </Button>
          <Button onPress={() => initiateOAuth("office365")} color="secondary" className="w-full">
            Connect Office 365
          </Button>
        </div>
      ) : (
        <Table
          classNames={{
            wrapper: "px-0 shadow-none",
            tr: "hover:bg-ash cursor-pointer",
          }}
          aria-label="Mailboxes Table"
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody emptyContent="No mailboxes added" items={mailboxes || []}>
            {(item) => (
              <TableRow
                onClick={() => {
                  setSelectedMailbox(item);
                  setOpen(true);
                }}
                key={item._id.toString()}
              >
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.provider}</TableCell>
                <TableCell>
                  <div className="text-sm text-label">{item._id.toString()}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
