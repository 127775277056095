import { Button, Checkbox, Input } from "@nextui-org/react";
import { Formik, Form, useFormikContext } from "formik";
import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { client } from "../../../utils/Client";

interface OneflowSettingsFormValues {
  apiKey: string;
  templateGroupId: string;
  workspaceId: string;
  enabled: boolean;
}

export default function Oneflow() {
  const { me } = useContext(AuthContext);
  const oneflowSettings = me.currentTeam?.integrations?.oneflow;

  const onSubmit = async (values: OneflowSettingsFormValues, { setSubmitting }: any) => {
    const team = await client.service("teams").get(me.currentTeam?._id.toString());
    const updatedIntegrations = {
      ...team.integrations, // Spread existing integrations
      oneflow: values, // Your new or updated oneflow values
    };
    await client.service("teams").patch(me.currentTeam?._id.toString(), {
      integrations: updatedIntegrations,
    });
    setSubmitting(false); // Always good practice to handle submission state
  };

  return (
    <div>
      <svg className="w-auto h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1098.93 241.98" fill="currentColor">
        <path d="M592.78 46.09h30.34v193.08h-30.34zM401.43 213.49c-21.59 0-38.39-12.87-44.62-31.49l117-30.29c-5-46.76-39.32-71.13-74.64-71.13a73.48 73.48 0 00-56 25.56C330.73 120.66 324 139.88 324 161c0 49.72 35 80.44 78 80.44 28.83 0 51.2-13 63.19-30.15l-21.45-17.94c-8.74 12.86-25.87 20.14-42.31 20.14zM399 108.67c19.16 0 32.86 10.26 39.79 25.55l-84.62 21.89c.5-26.77 17.99-47.44 44.83-47.44zM242.55 80.6c-40.45 0-66 25.82-66 64.72v93.85h30.35v-93.85c0-23.29 11.38-35.94 35.63-35.94S278 122 278 145.32v93.85h30.35v-93.85c.01-38.9-25.35-64.72-65.8-64.72zM80.7 241.48A80.45 80.45 0 11161.14 161a80.53 80.53 0 01-80.44 80.48m0-132c-27.56 0-50 23.14-50 51.59s22.42 51.58 50 51.58 50-23.14 50-51.58-22.42-51.59-50-51.59M719 241.48A80.45 80.45 0 11799.44 161 80.54 80.54 0 01719 241.48m0-132c-27.56 0-50 23.14-50 51.59s22.43 51.58 50 51.58 50-23.14 50-51.58-22.42-51.59-50-51.59M520.3 114.84v-8.08c0-13.47.5-19.49 6.14-26.16S542 70.93 555 70.93a85.3 85.3 0 0119 1.77V45.39a110.43 110.43 0 00-23.12-2.25c-19.56 0-33.45 4.76-43.32 12.85-13.35 10.94-17.49 25.2-17.49 48.44v134.74h30.34V141.4l41.88-10.93V103.9zM1016.05 143.05v33.76c0 23.28-11.33 35.93-35.24 35.94-11.92 0-20.84-3.43-26.52-9.44-6.74-7.12-8.45-14.39-8.49-26.29V83h-30.34v93.86c0 13.3-2.78 20.87-8.65 26.7s-14.63 9.23-26.58 9.24c-23.63 0-35-12.73-35-35.94V83h-30.36v97.37c0 17.93 5 32.3 16.36 43.45 13 12.7 30.17 17.76 49.22 17.76 21 0 38.87-7.87 50.18-20.73 11.26 12.81 29.09 20.73 50.41 20.73 20 0 37.22-6 48.52-17.28 11.51-11.48 16.83-24.38 16.83-45.58v-35.67zM1016.04 22.24h30.35v30.35h-30.35zM1016.04 82.93h30.35v30.34h-30.35zM985.7 52.59h30.35v30.34H985.7zM1046.39 52.59h30.35v30.34h-30.35zM1068.58 0h30.35v30.35h-30.35z"></path>
      </svg>
      <div className="mt-8 space-y-4">
        <Formik
          initialValues={
            oneflowSettings ||
            ({
              apiKey: "",
              templateGroupId: "",
              workspaceId: "",
              enabled: false,
            } as OneflowSettingsFormValues)
          }
          onSubmit={onSubmit}
          enableReinitialize // This tells Formik to reset the form when initialValues changes
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="space-y-4">
                <Input
                  label="API-token"
                  value={values.apiKey || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("apiKey", e.target.value)}
                />
                <Input
                  label="Template Group ID"
                  value={values.templateGroupId || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("templateGroupId", e.target.value)}
                />
                <Input
                  label="Workspace ID"
                  value={values.workspaceId || ""} // Ensure value is controlled
                  onChange={(e) => setFieldValue("workspaceId", e.target.value)}
                />
                <div>
                  <Checkbox
                    isSelected={values.enabled}
                    onChange={(e) => {
                      setFieldValue("enabled", e.target.checked);
                    }}
                  >
                    Enabled
                  </Checkbox>
                </div>
                <Button type="submit">Submit</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
