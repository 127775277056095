import { User } from "feathers-backend";
import { Formik, useFormik } from "formik";
import { apiUrl, client } from "../../utils/Client";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, Input } from "@nextui-org/react";
import { UserContext } from "../../contexts/UserContext";
import { useDropzone } from "react-dropzone";
import { DataContext } from "../../contexts/DataContext";
import { error } from "console";
import { object, string, ref } from "yup";

export default function Links() {
  return <></>;
}
