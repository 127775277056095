import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/Icons";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Set the API URL for login
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";

// Login form values interface
interface LoginFormValues {
  email: string;
  password: string;
}

// Initial form values
const initialValues: LoginFormValues = {
  email: "",
  password: "",
};

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string().min(6, "Password should be minimum 6 characters").required("Required"),
});

export default function Login() {
  const [authMessage, setAuthMessage] = useState("");
  const navigate = useNavigate();

  // Login function using fetch
  const login = async (email: string, password: string) => {
    try {
      const response = await fetch(`${apiUrl}/authentication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          strategy: "local",
          email: email,
          password: password,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // Store JWT token in local storage (or session storage)
        localStorage.setItem("feathers-jwt", result.accessToken);

        // Redirect to home page on successful login
        navigate("/");
      } else {
        setAuthMessage(result.message || "Invalid email or password");
      }
    } catch (error: any) {
      setAuthMessage("An error occurred. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      login(values.email, values.password);
    },
  });

  return (
    <div className="h-screen bg-cc-900">
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Logo />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cc-purple sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="mt-2 text-xs text-red-400">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="mt-4">
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                  Password
                </label>
                <div className="text-sm">
                  <Link to="/forgot-password" className="font-semibold text-cc-purple hover:text-cc-purple">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cc-purple sm:text-sm sm:leading-6"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="mt-2 text-xs text-red-400">{formik.errors.password}</div>
                ) : null}
              </div>
            </div>
            {authMessage && <div className="mt-4 text-xs text-red-400">{authMessage}</div>}
            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
