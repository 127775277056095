import { BellSnoozeIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar as AvatarComponent, Badge } from "@nextui-org/react";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

type AvatarProps = {
  status?: string;
  image?: string;
  size?: "sm" | "md" | "lg";
  radius?: "sm" | "md" | "lg" | "none" | "full";
  name: string;
};

// Define a type for color and placement to ensure valid values
type ColorType = "primary" | "warning" | "success" | "danger" | "default" | "secondary" | undefined;
type PlacementType = "top-right" | "bottom-right" | "top-left" | "bottom-left" | undefined;

export default function Avatar({
  status = "inProgress", // Default status if none is provided
  image,
  size = "md",
  radius = "md",
  name,
}: AvatarProps) {
  const { lang } = useContext(UserContext);

  // Helper function to determine styles, badge content, and placement based on status
  function getStatusProps(status: string): {
    color: ColorType;
    badgeContent?: JSX.Element;
    badgeColor?: ColorType;
    placement?: PlacementType;
  } {
    switch (status) {
      case "unassigned":
        return { color: "primary", badgeContent: undefined, badgeColor: undefined, placement: "bottom-right" };
      case "followup":
        return {
          color: "warning",
          badgeContent: <BellSnoozeIcon className="w-3 h-3" />,
          badgeColor: "warning",
          placement: "bottom-right",
        };
      case "closedWon":
        return {
          color: "success",
          badgeContent: <CheckIcon className="w-3 h-3" />,
          badgeColor: "success",
          placement: "bottom-right",
        };
      case "closedLost":
        return {
          color: "danger",
          badgeContent: <XMarkIcon className="w-3 h-3 text-pink-950" />,
          badgeColor: "danger",
          placement: "bottom-right",
        };
      case "inProgress":
        return { color: "default", badgeContent: undefined, badgeColor: undefined, placement: "bottom-right" };
      case "new":
        return {
          color: "primary",
          badgeContent: <div className="text-xs">{lang("New")}</div>,
          badgeColor: "primary",
          placement: "top-left",
        }; // Custom placement for "new"
      default:
        return { color: "default", badgeContent: undefined, badgeColor: undefined, placement: "bottom-right" };
    }
  }

  const { color, badgeContent, badgeColor, placement } = getStatusProps(status);

  const avatar = (
    <AvatarComponent
      imgProps={{ loading: "eager" }}
      showFallback
      name={name}
      color={color} // Use the color derived from status
      className="text-white bg-card"
      radius={radius}
      isBordered
      size={size}
      src={image}
    />
  );

  return badgeContent ? (
    <Badge
      isOneChar={status !== "new"}
      content={badgeContent}
      color={badgeColor}
      placement={placement || "bottom-right"}
    >
      {avatar}
    </Badge>
  ) : (
    avatar
  );
}
