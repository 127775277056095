import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Select, SelectItem } from "@nextui-org/react";

export default function SelectSource({ ...props }: any) {
  const { me } = useContext(AuthContext);
  const [sourceObjArray, setSourceObjArray] = useState<any>();

  useEffect(() => {
    if (me.currentTeam.sources) {
      setSourceObjArray(me.currentTeam.sources.map((source) => ({ value: source })));
    }
  }, [me]);

  return (
    <div className="flex flex-wrap w-full gap-4 md:flex-nowrap">
      {sourceObjArray?.length > 0 && (
        <Select
          {...props}
          selectedKeys={[props.value]}
          items={sourceObjArray}
          label="Source"
          placeholder="Select source"
        >
          {(source: any) => <SelectItem key={source.value}>{source.value}</SelectItem>}
        </Select>
      )}
    </div>
  );
}
