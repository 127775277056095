import { Chip, Select, SelectItem, Selection } from "@nextui-org/react";
import { ChangeEventHandler, Key, useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { UserContext } from "../../../contexts/UserContext";
import { AuthContext } from "../../../contexts/AuthContext";

type SelectTagsProps = {
  name: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  useDefaultTags?: boolean;
  value: [] | string[] | Key[] | undefined | (string | {})[] | null;
};

export default function SelectTags({ name, onChange, useDefaultTags = true, value }: SelectTagsProps) {
  const [selectedTags, setSelectedTags] = useState<Selection>(new Set());
  const { tags } = useContext(DataContext);
  const { lang } = useContext(UserContext);

  useEffect(() => {
    setSelectedTags(new Set(value?.map((tag) => tag.toString())));
  }, []);

  const handleChange = (e: any) => {
    const selectedTags = e.target.value.split(",");

    // Delete any empty strings from the array
    const filteredTags = selectedTags.filter((tag: string) => tag !== "");

    setSelectedTags(new Set(filteredTags));
    return onChange({
      target: {
        name,
        value: filteredTags,
      },
    } as any);
  };

  return (
    <>
      {tags && tags.length !== 0 && (
        <Select
          name={name}
          items={tags}
          selectedKeys={selectedTags}
          variant="bordered"
          isMultiline={true}
          selectionMode="multiple"
          onChange={handleChange}
          placeholder={lang("Select tags")}
          size="sm"
          color="primary"
          aria-label="Select tags"
          classNames={{
            base: "w-full border-none",
            trigger: "border-none",
          }}
          renderValue={(items) => {
            return (
              <div className="flex flex-wrap gap-2">
                {items.map((item) => (
                  <Chip
                    startContent={
                      <div style={{ backgroundColor: item.data?.color }} className="w-2 h-2 rounded-full" />
                    }
                    classNames={{
                      base: "text-black text-xs",
                    }}
                    style={{ color: item.data?.color }}
                    key={item.data?._id.toString()}
                  >
                    {item.data?.text}
                  </Chip>
                ))}
              </div>
            );
          }}
        >
          {(tag) => (
            <SelectItem aria-label={tag.text} key={tag._id.toString()} textValue={tag.text}>
              <div className="flex" style={{ color: tag.color }}>
                {tag.text}
              </div>
            </SelectItem>
          )}
        </Select>
      )}
    </>
  );
}
