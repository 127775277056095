import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import GenericTable from "../../components/Table/GenericTable";
import { DataContext } from "../../contexts/DataContext";
import OpportunityForm from "../../components/Forms/Opportunity/OpportunityForm";
import { ModalContext } from "../../contexts/ModalContext";
import { Opportunity } from "feathers-backend";

export default function Deals() {
  const { lang } = useContext(UserContext);
  const { opportunities, users } = useContext(DataContext);
  const { openModal } = useContext(ModalContext);
  return (
    <div className="flex flex-col space-y-10">
      <div className="flex items-center justify-between">
        <h1 className="ml-2 text-2xl font-light">{lang("Deals")} </h1>
      </div>
      <div className="flex">
        {opportunities.length > 0 && users.length > 0 && (
          <GenericTable
            data={opportunities}
            onRowClick={(item) => {
              openModal("", <OpportunityForm opportunity={item as Opportunity} />);
            }}
            defaultSort={{ column: "closedDate", direction: "descending" }}
            filterOnTags={true}
            filterOnUser={true}
            columns={[
              {
                name: lang("Deal"),
                uid: "deal",
                sortable: false,
              },
              {
                name: lang("Value"),
                uid: "value",
                sortable: true,
              },
              {
                name: lang("Tags"),
                uid: "tags",
                sortable: false,
              },
              {
                name: lang("Channel"),
                uid: "channel",
                sortable: true,
              },
              {
                name: lang("Created at"),
                uid: "createdAt",
                sortable: true,
              },
              {
                name: lang("Closed Date"),
                uid: "closedDate",
                sortable: true,
              },
              {
                name: lang("Closed By"),
                uid: "closedBy",
                sortable: true,
              },
            ]}
            statusOptions={[
              { name: "Won", uid: "closedWon" },
              { name: "Lost", uid: "closedLost" },
            ]}
            initialVisibleColumns={["deal", "value", "createdAt", "closedDate", "tags", "channel", "closedBy"]}
          />
        )}
      </div>
    </div>
  );
}
