import { createContext, useContext, useEffect, useState } from "react";
import en from "./lang/en.json";
import sv from "./lang/sv.json";
import { AuthContext } from "./AuthContext";

export const dictionaryList = { en, sv } as any;

export const languageOptions = {
  en: "English",
  sv: "Swedish",
};

export const UserContext = createContext({
  lang: (key: string) => "",
});

type UserSettings = {
  language: string;
  theme: string;
};

export function UserProvider(props: any) {
  const { me } = useContext(AuthContext);

  const [settings, setSettings] = useState<UserSettings>({
    language: "sv", // default to Swedish initially
    theme: "light",
  });

  // Update language once `me.language` is fetched
  useEffect(() => {
    if (me?.language) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        language: me.language || "sv",
      }));
    }
  }, [me]);

  const lang = (key: string) => {
    const translation = dictionaryList[settings.language][key];
    if (!translation) {
      return key;
    } else {
      //console.log("translation not found for key: ", key, " in language: ", settings.language);
      return translation;
    }
  };

  const setLanguage = (language: string) => {
    setSettings((prevSettings) => ({ ...prevSettings, language }));
  };

  const toggleTheme = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      theme: prevSettings.theme === "light" ? "dark" : "light",
    }));
  };

  const value = { settings, setLanguage, toggleTheme, lang };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}
