import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import { useCustomer } from "./CustomerShow";
import { Contact } from "feathers-backend";
import CustomerEditContactForm from "./CustomerEditContactForm";
import { ModalContext } from "../../contexts/ModalContext";
import { UserContext } from "../../contexts/UserContext";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function CustomerContacts() {
  const { contacts } = useContext(DataContext);
  const { lang } = useContext(UserContext);
  const { customer } = useCustomer();
  const [customerContacts, setCustomerContacts] = useState<Contact[]>([]);
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    // Filter the contacts to only show the ones that belong to the customer and are in the customer.contacts array
    const filteredContacts = contacts.filter((contact) => {
      if (customer) {
        return customer?.contacts?.includes(contact._id.toString());
      }
    });
    setCustomerContacts(filteredContacts);
  }, [contacts]);

  return (
    <>
      <div className="flex justify-end my-2">
        <Button
          onPress={() => {
            openModal(lang("Add contact"), <CustomerEditContactForm customer={customer} />);
          }}
          color="primary"
          endContent={<PlusIcon className="w-5 h-5" />}
        >
          {lang("Add contact")}
        </Button>
      </div>
      <Table aria-label="Example empty table">
        <TableHeader>
          <TableColumn>NAME</TableColumn>
          <TableColumn>EMAIL</TableColumn>
          <TableColumn>PHONE</TableColumn>
          <TableColumn>TITLE</TableColumn>
        </TableHeader>
        <TableBody items={customerContacts} emptyContent={"No rows to display."}>
          {(item) => (
            <TableRow
              className="overflow-hidden rounded-lg cursor-pointer hover:bg-opacity-30 hover:bg-ash"
              onClick={() => {
                openModal("Edit contact", <CustomerEditContactForm contact={item} />);
              }}
              key={item._id.toString()}
            >
              <TableCell>{item.firstName + " " + item.lastName}</TableCell>
              <TableCell>{item.email} </TableCell>
              <TableCell>{item.phone} </TableCell>
              <TableCell>{item.title} </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
