export default function getColor(str: string, s = 50, l = 80, opacity = 1) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%, " + opacity + ")";
}

export function getChannelColor(channel: string) {
  switch (channel) {
    case "Direct":
      return "#FFDF9A";
    case "Email":
      return "#FF968F";
    case "Organic Search":
      return "#A996FF";
    case "Organic Social":
      return "#50C8FC";
    case "Paid Search":
      return "#A6F787";
    case "Paid Social":
      return "#6DFDED";
    case "Referral":
      return "#DBD7D7";
    default:
      return getColor(channel);
  }
}
