import { client } from "../../utils/Client";
import { useReward } from "react-rewards";
import { Board } from "feathers-backend";

const Reward = () => {
  useReward("reward", "confetti", {
    angle: 200,
    spread: 90,
    elementCount: 100,
    lifetime: 200,
    elementSize: 4,
    startVelocity: 50,
    colors: ["#a996ff", "#55D486"],
  });
};

const GetListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "rgb(40 37 60 / 10%)" : "transparent",
});

export const AddColumn = (board: Board) => {
  client.service("columns").create({
    name: "New column",
    customfields: [],
    boardId: board._id,
    teamId: board.teamId,
    items: [],
  });
};
