import { Outlet } from "react-router-dom";
import Nav from "./Nav";
import { EnvelopeIcon, HeartIcon } from "@heroicons/react/24/outline";

export default function Settings() {
  return (
    <div className="px-4 mx-auto lg:px-8">
      <div className="flex justify-between pb-4 mb-8 space-x-8 ">
        <h1 className="text-2xl text-left text-offwhite">Settings</h1>
      </div>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
        <div className="col-span-1 space-y-8">
          <div className="p-8 rounded-lg bg-content1">
            <Nav />
          </div>
          <div className="block w-full p-8 text-white rounded-lg bg-content1 ">
            <div className="flex flex-col space-x-2 text-center">
              <HeartIcon className="w-10 h-10 mx-auto fill-primary stroke-none" aria-hidden="true" />
              <p className="my-auto text-lg">Need help?</p>
              <p className="mt-2 text-sm text-center text-cc-line">Do you have any questions or suggestions?</p>
              <div className="mx-auto mt-4">
                <a
                  href="mailto:dennis@newcustomers.se"
                  className="flex justify-center px-3 py-2 mx-auto mt-4 text-sm font-bold rounded-lg bg-ash hover:bg-body text-line"
                >
                  <EnvelopeIcon className="w-5 h-5 my-auto mr-2" aria-hidden="true" />
                  Ask a question
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 space-y-8">
          <div className="p-8 rounded-lg bg-content1 ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
