import { Board, Opportunity, User } from "feathers-backend";
import Formatter from "../../utils/Formatter";
import Avatar from "../Avatar";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import OpportunityForm from "../Forms/Opportunity/OpportunityForm";
import { DataContext } from "../../contexts/DataContext";
import UserAvatar from "../UserAvatar";
import { OneflowIcon } from "../Forms/Fields/Oneflow";
import { Chip } from "@nextui-org/react";
import { client } from "../../utils/Client";

type KanbanCardProps = {
  opportunity: Opportunity;
  setShowTagLabels: (showTagLabels: boolean) => void;
  showTagLabels: boolean;
  board: Board;
};

export default function KanbanCard({ setShowTagLabels, showTagLabels, opportunity, board }: KanbanCardProps) {
  const { openModal } = useContext(ModalContext);
  const { tags, users } = useContext(DataContext);
  const [user, setUser] = useState<User | any>(null);

  useEffect(() => {
    if (opportunity.assignedTo) {
      const user = users?.find((user) => user._id === opportunity.assignedTo);
      setUser(user);
    } else {
      setUser(null);
    }
  }, [users, opportunity.assignedTo]);

  // Function to update the opportunity from status new to inProgress when the opportunity is clicked
  const updateOpportunityStatus = async () => {
    if (opportunity.status === "new") {
      client.service("opportunities").patch(opportunity._id.toString(), { status: "inProgress" });
    }
  };

  const getTag = (tagId: string) => {
    const tag = tags?.find((tag) => tag._id === tagId);
    return tag;
  };

  return (
    <div className="flex flex-col">
      <div className="z-10 flex flex-col p-2 transition rounded-lg cursor-pointer bg-card hover:bg-ash">
        {opportunity && (
          <div
            onClick={() => {
              updateOpportunityStatus();
              openModal("", <OpportunityForm opportunity={opportunity} board={board} />);
            }}
          >
            <div className="relative flex justify-between">
              {opportunity.tags && opportunity.tags.length !== 0 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTagLabels(!showTagLabels);
                  }}
                  className="flex flex-wrap gap-1 my-1 ml-2 mr-4 "
                >
                  {opportunity.tags?.map((tag: any, i: number) => (
                    <div key={i} className="flex text-xs">
                      <Chip
                        startContent={
                          <div style={{ backgroundColor: getTag(tag)?.color }} className="w-2 h-2 rounded-full" />
                        }
                        size="sm"
                        classNames={{ base: "p-0 h-auto", content: "p-0 h-auto" }}
                        style={{ color: getTag(tag)?.color }}
                      >
                        {showTagLabels && <div className="ml-1">{getTag(tag)?.text}</div>}
                      </Chip>
                    </div>
                  ))}
                </div>
              )}
              {opportunity.oneflow && (
                <div className="absolute top-0 right-0">
                  <OneflowIcon oneflow={opportunity.oneflow} className="w-4 h-4" />
                </div>
              )}
            </div>
            <div className="flex">
              <div className="mt-2 ml-2 mr-4">
                <Avatar
                  name={opportunity.displayName ?? ""}
                  status={opportunity.status}
                  radius="md"
                  size="md"
                  image={opportunity.avatar}
                />
              </div>
              <div className="w-full overflow-hidden">
                <div className="mr-4 text-sm text-white break-words whitespace-normal">{opportunity.displayName}</div>
                <div className="text-sm text-placehold">{opportunity.customer?.name}</div>
                {opportunity.followUpDate && (
                  <div className="text-xs text-placehold">
                    {new Date(opportunity.followUpDate).toLocaleDateString("sv-SE")}
                  </div>
                )}
                <div className="grid grid-cols-2">
                  <div className="mt-1 text-xs text-cc-white">{Formatter(opportunity.expectedValue || 0)}</div>
                  {user && (
                    <div className="ml-auto">
                      <UserAvatar size="tiny" user={user} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
