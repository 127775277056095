import { Button, Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import { useFormik } from "formik";
import { client } from "../../utils/Client";
import { Tag } from "feathers-backend";
import { UserContext } from "../../contexts/UserContext";
import { useContext } from "react";

type TagFormProps = {
  tag?: Tag | null;
  setOpen: any;
};

const Colors = [
  { value: "#106BA5" },
  { value: "#1CAECA" },
  { value: "#37CCE3" },
  { value: "#55D486" },
  { value: "#5DAB4A" },
  { value: "#5EA5CD" },
  { value: "#72EBAB" },
  { value: "#7DC770" },
  { value: "#8DBDD8" },
  { value: "#92DFEA" },
  { value: "#A76FBF" },
  { value: "#B5F0D1" },
  { value: "#B8DCB1" },
  { value: "#CC8FE3" },
  { value: "#CD5242" },
  { value: "#DEC1EA" },
  { value: "#E36BAE" },
  { value: "#E5912C" },
  { value: "#E5C42F" },
  { value: "#ED7667" },
  { value: "#EEB3AC" },
  { value: "#F4DB40" },
  { value: "#F4E997" },
  { value: "#F8C3E3" },
  { value: "#F9D19F" },
  { value: "#FD90D3" },
  { value: "#FDAE4B" },
];

export default function TagForm({ tag, setOpen }: TagFormProps) {
  const { lang } = useContext(UserContext);
  const formik = useFormik({
    initialValues: tag || ({} as Tag),
    onSubmit: async (values) => {
      if (tag) {
        client.service("tags").patch(tag._id.toString(), values);
      } else {
        client.service("tags").create(values);
      }
      setOpen(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="space-y-4 ">
        <div className="grid gap-4 lg:grid-cols-2">
          <Input
            isRequired={true}
            label="Tag label"
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Select
            isRequired={true}
            name="color"
            label="Tag color"
            selectedKeys={[formik.values.color]}
            value={formik.values.color}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Colors.map((color) => (
              <SelectItem style={{ color: color.value }} textValue={color.value} key={color.value} value={color.value}>
                <div style={{ color: color.value }}>{color.value}</div>
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="flex justify-end gap-4">
          {tag && (
            <Button
              onPress={() => {
                client.service("tags").remove(tag._id.toString());
                setOpen(false);
              }}
              color="danger"
            >
              {lang("Delete")}
            </Button>
          )}
          <Button type="submit" color="primary">
            {tag ? lang("Save") : lang("Create")}
          </Button>
        </div>
      </div>
    </form>
  );
}
